<template>
    <div class="mainView">
        <div
            style="height: 5.6vw;display: flex;justify-content: space-between;align-items: center;flex-direction: row;background-color: white;">
            <div
                style="display: flex;justify-content: space-between;align-items: center;flex-direction: row;margin-left: 1.7vw;">
                <img src="../assets/yejia/logo.png" style="width:6.5vw;height: auto;" />
                <div
                    style="width: 8vw;height: 5.6vw;justify-content: center;align-items: center;display: flex;flex-direction: column;margin-left: 4vw;">
                    <span
                        style="font-size: 0.8vw;color: #333333;height: 5.5vw;justify-content: center;align-items: center;display: flex;">首页</span>
                    <div style="background-color: #4878A9;height: 0.2vw;width: 1.9vw;"></div>
                </div>
                <div
                    style="width: 8vw;height: 5.6vw;justify-content: center;align-items: center;display: flex;flex-direction: column;">
                    <span
                        style="font-size: 0.8vw;color: #999999;height: 5.5vw;justify-content: center;align-items: center;display: flex;">联系我们</span>
                    <div style="height: 0.2vw;width: 1.9vw;"></div>
                </div>
            </div>
            <div
                style="width: 14.7vw;background-color: #4777A7;height: 5.6vw;display: flex;justify-content: center;align-items: center;">
                <span style="color: white;font-size: 0.8vw;">Tel：13327326301</span>
            </div>
        </div>

        <div style="width: 100vw;height: 41.8vw;position: relative;">
            <img src="../assets/zhongguan/bg_1.png" style="width:100vw;height: auto;" />
            <div style="font-weight: bold;font-size: 3.4vw;color: #000000;position:absolute;top: 11.8vw;left: 9.2vw;">
                创新型互联网公司</div>
            <!-- <div style="font-weight: bold;font-size: 3.4vw;color: #000000;position:absolute;top: 17.3vw;left: 9.2vw;">
                2023年推广收入累计超过1亿</div> -->
        </div>
        <div style="margin-left: 8.6vw;position: relative;">
            <div style="display: flex;flex-direction: row;margin-bottom: 3.6vw;align-items: center;padding-top: 5.5vw;">
                <div style="background-color: #707070;height: 0.2vw;width: 1.9vw;"></div>
                <span style="font-size: 1.5vw;color: #333333;margin-left: 1vw;">服务领域</span>
            </div>
            <div style="font-size: 1.8vw;color: #000000;text-align: left;">
                <b>公司以运营商业务为主营业务，目前该项业务已覆盖全国，并且和包括腾讯、</b>
                <br><b>今日头条、快手、抖音，网易等在内的各大互联网平台形成了长期合作的关系</b>
            </div>
            <img src="../assets/zhongguan/icon_1.png"
                style="width:7.4vw;height: auto;position: absolute;top: 5.3vw;right: 8.7vw;" />
        </div>
        <div style="margin-top: 6.8vw;">
            <img src="../assets/zhongguan/item_1.png" style="width: 19.5vw;height: 11.5vw;">
            <img src="../assets/zhongguan/item_2.png" style="width: 19.5vw;height: 11.5vw;margin-left: 1.3vw;">
            <img src="../assets/zhongguan/item_3.png" style="width: 19.5vw;height: 11.5vw;margin-left: 1.3vw;">
            <img src="../assets/zhongguan/item_4.png" style="width: 19.5vw;height: 11.5vw;margin-left: 1.3vw;">
        </div>

        <div style="display: flex;background-color: #F3F4F4;width: 100vw;height: 57.9vw;margin-top: 5.9vw;">
            <div style="margin-left: 8.6vw; margin-top: 3.8vw;flex: 1;text-align: left;">
                <img src="../assets/zhongguan/title_1.png" style="width:26vw;height: auto;" />
                <div
                    style="display: flex;flex-direction: row;justify-content: left ;align-items: center;margin-top: 6.4vw;">
                    <img src="../assets/zhongguan/01.png" style="width:7.1vw;height: auto;" />
                    <div style="margin-left: 1.8vw;text-align: left;">
                        <div style="font-size: 1.3vw;color: #000000;font-weight: bold;">市场营销</div>
                        <div style="font-size: 0.8vw;color: #999999;margin-top: 0.6vw;">marketing management</div>
                    </div>
                </div>

                <div
                    style="display: flex;flex-direction: row;justify-content: left ;align-items: center;margin-top: 3vw;">
                    <img src="../assets/zhongguan/02.png" style="width:7.1vw;height: auto;" />
                    <div style="margin-left: 1.8vw;text-align: left;">
                        <div style="font-size: 1.3vw;color: #000000;font-weight: bold;">品牌推广</div>
                        <div style="font-size: 0.8vw;color: #999999;margin-top: 0.6vw;">branding</div>
                    </div>
                </div>

                <div
                    style="display: flex;flex-direction: row;justify-content: left ;align-items: center;margin-top: 3.3vw;">
                    <img src="../assets/zhongguan/03.png" style="width:7.1vw;height: auto;" />
                    <div style="margin-left: 1.8vw;text-align: left;">
                        <div style="font-size: 1.3vw;color: #000000;font-weight: bold;">用户运营</div>
                        <div style="font-size: 0.8vw;color: #999999;margin-top: 0.6vw;">User Operations</div>
                    </div>
                </div>

                <div
                    style="display: flex;flex-direction: row;justify-content: left ;align-items: center;margin-top: 3.3vw;">
                    <img src="../assets/zhongguan/04.png" style="width:7.1vw;height: auto;" />
                    <div style="margin-left: 1.8vw;text-align: left;">
                        <div style="font-size: 1.3vw;color: #000000;font-weight: bold;">数据分析</div>
                        <div style="font-size: 0.8vw;color: #999999;margin-top: 0.6vw;">data analysis</div>
                    </div>
                </div>
            </div>
            <div style="width:46.8vw;height: 33.8vw;margin-top: 16.3vw;margin-right: 5vw;position: relative;">
                <img src="../assets/zhongguan/con_1.png" style="width:46.8vw;height: auto;" />
                <div style="position: absolute;top: 30.9vw;display: flex;flex-direction: column;margin-left: 4vw;text-align: left;">
                    <span style="color: #FFFFFF;font-size: 1vw;">具有丰富的市场营销、品牌推广、用户运营和数据分析经验</span>
                    <span style="color: #FFFFFF;font-size: 0.7vw;margin-top: 1vw;">围绕四大运营商(移动，联通，电信、中国广电) 开展合作</span>
                    <div style="width: 2.8vw;height: 0.2vw;background-color: #FFFFFF;margin-top: 2.6vw;"></div>
                </div>
            </div>
        </div>

        <div style="width: 100%;height: 42.5vw;position: relative;">
            <img src="../assets/zhongguan/bg_2.png" style="width:100vw;height: auto;" />
            <img src="../assets/zhongguan/title_2.png"
                style="width:44.6vw;height: auto;position: absolute;top: 3vw;left: 8.6vw;" />
            <div
                style="font-size: 1vw;color: #FFFFFF;text-align: left;position: absolute;top: 12.3vw;left: 8.6vw;width: 45vw;line-height: 2vw;">
                我司拥有丰富的渠道资源，与各类线上线下渠道合作，建立了长期稳定的合作关系，共同致力于产品销售和市场推广，
                迅速将产品推向市场，覆盖更广泛的客户群体，实现销售增长和市场份额的提升。
            </div>
        </div>
        <div
            style="width: 100vw; display: flex;justify-content: left;flex-direction: column;margin-left: 8.6vw;margin-top: 4.3vw;margin-bottom: 4.2vw;">
            <img src="../assets/zhongguan/title_3.png" style="width:30.9vw;height: auto;" />
            <img src="../assets/zhongguan/bg_3.png" style="width:81.1vw;height: auto;" />
        </div>
        <div
            style="width: 100vw;height: 5.1vw;background-color: #171E24;justify-content: center;align-items: center;color: white;font-size: 1vw;display: flex;">
            Copyright ◎ 2024 杭州掖迦科技有限公司 版权所有 浙ICP备2023031894号-1 浙ICP备2023031894号-2
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            checked: false
        };
    },
}
</script>

<style lang="scss" scoped>
.mainView {
    width: 100vw;
    height: 100vh;
}
</style>
import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/YeJiaView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // 添加通配符路由，将所有未匹配到的路径重定向到首页
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const router = createRouter({
  //Hash会加上#但是这样就支持本地打开了
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
